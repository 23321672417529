.fixed {
   position: fixed;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;
   background: rgba(20, 22, 27, 0.95);
   z-index: 1000;
   overflow-x: hidden;
   overflow-y: auto;
   background-size: cover;


   @media (max-width: 700px) {
      background: #000;
   }
}

.dialog {
   max-width: 1139px;
   margin: 0 auto;
   min-height: 100%;
   display: flex;
   justify-content: center;
   padding: 0px 35px;

   @media (max-height: 960px) and (min-width: 1200px) {
      padding: 23px 35px;
   }

   @media (max-width: 1200px) {
      padding: 23px 35px;
   }

   @media (max-width: 700px) {
      padding: 6px 12px 20px 12px;
      overflow: hidden;
   }

   @media (max-width: 700px) {
      padding: 0px;
   }
}

.content {
   position: relative;
   z-index: 5;
   width: 100%;
   flex-grow: 1;
   display: flex;
   flex-direction: column;
}

.close {
   margin-left: 58px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #FFF;
   transition: color 0.3s ease-in-out;
   cursor: pointer;
   position: absolute;
   top: 20px;
   right: 20px;
   z-index: 10;

   @media (max-width: 700px) {
      margin: 0;
      top: 32px;
   }

   svg {
      pointer-events: none;
      width: 20px;
      height: 20px;

      @media (max-width: 700px) {
         width: 16px;
         height: 16px;
      }
   }

   &:hover {
      color: green;
   }
}

.actual {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: auto;
   margin-bottom: auto;

   @media (max-width: 700px) {
      height: 100%;
   }
}

.actual_slider_wrap {
   position: relative;
   width: 426px;

   @media (max-width: 700px) {
      margin-bottom: 24px;
      width: calc(100% - 70px);
   }

   @media (max-width: 700px) {
      width: 100%;
      margin: 0;
   }

   .main_slider {
      overflow: visible;

      &_slide {
         padding: 0;
         transition: transform .3s ease-in-out;

         @media (max-width: 700px) {
            padding: 0;
         }
      }
   }
}

.actual_slider {
   position: relative;

   .item_actual {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      position: relative;
      padding: 25px 24px;
      aspect-ratio: 9 / 16;
      min-height: 0;
      border-radius: 25px;
      overflow: hidden;
      box-sizing: border-box;

      @media (max-width: 700px) {
         border-radius: 0;
      }

      &:not(:last-child) {
         margin-right: 0;
      }

      &__content {
         position: relative;
         z-index: 3;
         display: flex;
         flex-direction: column;
         align-items: flex-end;
         justify-content: flex-end;
         width: 100%;
      }

      &__btn {
         color: #FFF;
         font-size: 16px;
         font-weight: 500;
         line-height: 95%;
         height: 44px;
         display: flex;
         align-items: center;
         justify-content: center;
         text-decoration: none;
         border-radius: 21.876px;
         background: #A2CC39;
         gap: 25px;
         padding: 0px 17px;

         &::after {
            display: block;
            content: "";
            width: 18px;
            height: 15px;
            background: url("data:image/svg+xml,%3Csvg width='19' height='12' viewBox='0 0 19 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5303 6.53033C18.8232 6.23744 18.8232 5.76256 18.5303 5.46967L13.7574 0.696699C13.4645 0.403806 12.9896 0.403806 12.6967 0.696699C12.4038 0.989593 12.4038 1.46447 12.6967 1.75736L16.9393 6L12.6967 10.2426C12.4038 10.5355 12.4038 11.0104 12.6967 11.3033C12.9896 11.5962 13.4645 11.5962 13.7574 11.3033L18.5303 6.53033ZM0 6.75L18 6.75V5.25L0 5.25L0 6.75Z' fill='white'/%3E%3C/svg%3E%0A") center center no-repeat;
            background-size: contain;
         }
      }

      &__img {
         position: absolute;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         pointer-events: none;

         img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
         }

         video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }

      &__form {
         display: flex;
         flex-direction: column;
         margin: -25px -24px;
         width: calc(100% + 48px);
         height: calc(100% + 50px);
         background: #FFF;
         z-index: 3;
         justify-content: center;
         padding: 25px 50px;
         position: relative;
         box-sizing: border-box;
         gap: 20px;

         &_close {
            position: absolute;
            top: 25px;
            right: 28px;
            cursor: pointer;
         }

         &_title {
            color: #303538;
            font-size: 38px;
            font-weight: 400;
            line-height: 95%;
         }

         &_error {
            color: #ff0000;
            font-size: 18px;
            font-weight: 400;
            line-height: 95%;
         }

         &_success {
            color: #303538;
            font-size: 25px;
            font-weight: 400;
            line-height: 95%;
         }

         form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            input[type=text] {
               border-radius: 7px;
               background: #F5F6F7;
               padding: 21px 25px;
               font-size: 18px;
               font-weight: 400;
               line-height: 95%;
               border: none;
               display: block;
               color: #303538;
            }

            input[type=submit] {
               border-radius: 41.143px;
               background: #265CF4;
               padding: 18px;
               text-align: center;
               font-size: 18px;
               color: #FFF;
               font-weight: 400;
               line-height: 95%;
               border: none;
               display: block;
            }

            input[type=checkbox] {
               display: none;
            }

            label {
               color: #303538;
               font-size: 13px;
               font-weight: 400;
               line-height: 100%;
               display: flex;
               align-items: flex-start;
               justify-content: flex-start;
               gap: 13px;
               position: relative;
               cursor: pointer;

               a {
                  color: #265CF4;
                  text-decoration: none;
               }

               &::before {
                  display: block;
                  content: "";
                  border-radius: 3px;
                  border: 1px solid #B0C5FF;
                  width: 16px;
                  height: 16px;
                  flex-shrink: 0;
               }
            }

            input[type=checkbox]:checked+label::after {
               display: block;
               content: "";
               background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='9' viewBox='0 0 8 9' fill='none'%3E%3Cpath d='M1 6L4 7.5L6.5 1' stroke='%23265CF4' stroke-width='2'/%3E%3C/svg%3E%0A");
               width: 7.5px;
               height: 8.5px;
               flex-shrink: 0;
               position: absolute;
               left: 5px;
               top: 4px;
            }
         }
      }
   }
}

.actual_progress {
   position: absolute;
   z-index: 5;
   left: 33px;
   top: 12px;
   right: 33px;
   height: 3px;
   background-color: rgba(217, 217, 217, 0.3);
   border-radius: 3px;
   display: flex;
   gap: 2px;
   transition: opacity .3s ease-in-out;

   &_hide {
      display: none;
   }

   @media (max-width: 700px) {
      left: 13px;
      right: 13px;
   }

   &__line {
      position: relative;
      width: 100%;
      height: 3px;

      span {
         position: absolute;
         left: 0;
         top: 0;
         height: 100%;
         background-color: rgba(217, 217, 217, 0.6);
         display: block;
      }

      &:first-child span {
         border-radius: 3px 0 0 3px;
      }

      &:last-child span {
         border-radius: 0 3px 3px 0;
      }
   }
}


.action {
   display: flex;
   align-items: center;
   position: absolute;
   top: 27px;
   right: 33px;
   z-index: 5;
   transition: opacity .3s ease-in-out;
   gap: 12px;
   padding: 0;
   margin: 0;
   list-style: none;

   @media (max-width: 700px) {
      right: 13px;
   }

   @media (max-width: 700px) {
      left: 13px;
      right: auto;
      top: 29px;
   }

   .btn_action {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.6);
      min-width: 58px;
      padding: 0;
      height: auto;
      min-width: 0;
      border: none;
      background: transparent;
      border-radius: 3px;
      transition: color 0.3s ease-in-out;
      box-shadow: none;

      &:hover {
         color: rgba(255, 255, 255, 1);
      }

      &:active {
         color: blue;
      }

   }


   .icon_sound {
      width: 24px;
      height: 24px;
   }

}

.main_slider_slide:not(:global(.swiper-slide-active)) {
   transform: translateZ(0) scale(0.7);

   .action,
   .actual_progress {
      opacity: 0;
   }

   @media (max-width: 700px) {
      transform: translateZ(0) scale(0.85);
      opacity: 0.6;
   }
}

.actual_slider_next,
.actual_slider_prev {
   width: 34px;
   height: 34px;
   position: absolute;
   top: calc(50% - 10px);
   color: #cecece;
   transition: color 0.3s ease-in-out;
   overflow: hidden;
   z-index: 1000;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;

   @media (max-width: 700px) {
      display: none;
   }

   &:hover {
      color: green;
   }

   &:focus {
      outline: none;
   }

   &.swiper-button-disabled {
      cursor: auto;

      &:hover {
         color: #cecece;
      }
   }
}

.actual_slider_prev {
   left: -47px;

   @media (max-width: 700px) {
      left: -40px;
   }

   .icon_arrow_prev {
      width: 34px;
      height: 34px;
      transform: rotate(-90deg);
      fill: #FFF;
   }
}

.actual_slider_next {
   right: -47px;

   @media (max-width: 700px) {
      right: -40px;
   }

   .icon_arrow_next {
      width: 34px;
      height: 34px;
      transform: rotate(90deg);
      fill: #FFF;
   }
}

.enterActive {
   animation: fadeIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.exitActive {
   animation: fadeOut 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }
}

@keyframes fadeOut {
   0% {
      opacity: 1;
   }

   100% {
      opacity: 0;
      ;
   }
}