@font-face {
    font-family: "Jost";
    src: local("Jost"), local("Jost"), url(./fonts/Jost.woff2) format("woff2"), url(./fonts/Jost.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Jost";
    src: local("Jost Medium"), local("Jost"), url(./fonts/Jost.woff2) format("woff2"), url(./fonts/Jost.woff) format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap
}
*{
    font-family: "Jost", sans-serif;
    outline: none;
}